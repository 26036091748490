<template>
  <div class="main">
    <!-- loading -->
    <div
      class="loadings"
      v-if="loading"
      v-loading="{ visible: loading, text: loadingtext }"
    ></div>
    <islogin />
    <nyheads />

    <div class="ny-zhuti">
      <nrnav />

      <div class="ny-liucheng">
        <div class="ny-liucheng-title">广西卫生</div>
        <div class="ny-liucheng-ul">
          <div class="ny-liucheng-li">
            <div class="ny-liucheng-li-title">
              <div class="ny-liucheng-li-title-icon">1</div>
              <div class="ny-liucheng-li-title-wz">资料提交</div>
            </div>
            <div class="ny-liucheng-li-bcnr"></div>
          </div>
          <div class="ny-liucheng-li">
            <div class="ny-liucheng-li-title">
              <div class="ny-liucheng-li-title-icon">2</div>
              <div class="ny-liucheng-li-title-wz">文献检索</div>
            </div>
            <div class="ny-liucheng-li-bcnr"></div>
          </div>
          <div class="ny-liucheng-li">
            <div class="ny-liucheng-li-title">
              <div class="ny-liucheng-li-title-icon">3</div>
              <div class="ny-liucheng-li-title-wz">聚类分析</div>
            </div>
            <div class="ny-liucheng-li-bcnr"></div>
          </div>
          <div class="ny-liucheng-li">
            <div class="ny-liucheng-li-title">
              <div class="ny-liucheng-li-title-icon">4</div>
              <div class="ny-liucheng-li-title-wz">文献精选</div>
            </div>
            <div class="ny-liucheng-li-bcnr"></div>
          </div>
          <div class="ny-liucheng-li">
            <div class="ny-liucheng-li-title">
              <div class="ny-liucheng-li-title-icon">5</div>
              <div class="ny-liucheng-li-title-wz">深度评估</div>
            </div>
            <div class="ny-liucheng-li-bcnr"></div>
          </div>
          <div class="ny-liucheng-li">
            <div class="ny-liucheng-li-title">
              <div class="ny-liucheng-li-title-icon">6</div>
              <div class="ny-liucheng-li-title-wz">报告输出</div>
            </div>
            <div class="ny-liucheng-li-bcnr"></div>
          </div>
        </div>
      </div>

      <div class="ny-neirong">
        <div class="ny-nav">
          <router-link to="/">论文助手</router-link
          >&nbsp;&nbsp;>&nbsp;&nbsp;广西卫生
        </div>
        <div class="ny-con">
          <div class="tjlw">
            <div class="tjlw-img">
              <div class="tjlw-img-txt">
                <el-image
                  style="width: 48px; height: 40px"
                  src="/img/tijiaobiaoti/icon1.png"
                  fit="fill"
                />
                <span
                  >你只负责输入标题，写论文的这三个月，论文助手来帮你节省</span
                >
              </div>
              <el-image
                style="width: 1000px; height: 119px"
                src="/img/tijiaobiaoti/bj1.png"
                fit="fill"
              />
            </div>
            <div class="tjlw-title">广西卫生</div>
            <el-form
              :label-position="'top'"
              label-width="200px"
              :model="form"
              style="max-width: 800px"
            >
              <el-form-item label="请选择论文模型">
                <el-radio-group v-model="moban.moban" @change="changemoban">
                  <el-radio
                    v-for="(item, key) in moban.options"
                    :key="key"
                    :value="item.mbid"
                    :label="item.mbname"
                  ></el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="关键词" class="biaotiyouhua-item">
                <el-input
                  v-model="title.bt"
                  placeholder="必填，请输入若干关键词，用于系统分析"
                />
              </el-form-item>
              <el-form-item label="附加资料" class="biaotiyouhua-item">
                <el-input
                  rows="3"
                  type="textarea"
                  v-model="title.changwenben"
                  placeholder="选填，请输入附加资料，长度一万字以内，用于获取更加准确的分析结果"
                />
              </el-form-item>
              <div class="ckwx-btn">
                <el-button type="primary" @click="shengchengbiaoti"
                  >生成标题</el-button
                >
              </div>
              <el-form-item
                class="titleClass"
                label="选择标题"
                v-if="title.titles.length != 0"
              >
                <el-radio-group v-model="title.title">
                  <el-radio
                    :value="item"
                    v-for="item in title.titles"
                    :key="item"
                    :label="item"
                  ></el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="标题">
                <el-input v-model="title.title" />
              </el-form-item>

              <div class="tjlw-btns">
                <el-button size="large" type="primary" @click="shengchenglunwen"
                  >生成论文</el-button
                >
                <span class="tjlw-btns-link" @click="clickckjg">查看结果</span>
              </div>
            </el-form>
          </div>
        </div>
      </div>
    </div>

    <footernew />
  </div>
</template>

<script setup>
import {
  Delete,
  Edit,
  Search,
  Share,
  TrophyBase,
  Refresh,
} from "@element-plus/icons-vue";
import { post, get } from "@/utils/index";
import { ElMessage, ElMessageBox } from "element-plus";
import { reactive, ref } from "vue";
import { useRouter } from "vue-router";

const router = useRouter();
// loading
let loading = ref(false);
let loadingtext = ref("生成中，请等待...");
let randsum = 0;
let form = reactive({});

// 模板
let moban = reactive({
  id: 0,
  moban: "",
  options: [],
});
// 标题
let title = reactive({
  bt: "", //输入的关键词
  changwenben: "", //输入的长文本
  fanhuichangwenben: "", //接口返回的长文本
  keys: "", //接口返回的关键词
  titles:[], //最终获取的选题集合
  title:'',//最终选择的标题
});
// 参考文献
let cankaowenxian = reactive({
  cankaowenxian: "", //40条参考文献
  cankaowenxianjingxuan: "", //典型精选
});
// 潜在规律
let qianzaiguilv = reactive({
  qianzaiguilvs: "", //返回的全部潜在规律
  qianzaiguilv: "", //选取的潜在规律
});

// 获取模板列表
const getmoban = () => {
  get("/api/Lw/GetMobanList", {
    typeid: 5,
  }).then((res) => {
    moban.options.push(...res.data);
  });
};
getmoban();

// 选择分类下的模版
const changemoban = (val) => {
  moban.id = moban.options.filter((e) => {
    return val == e.mbname;
  })[0].mbid;
};


// 获取潜在规律
const huoquqianzaiguilv = async () => {
  return post("/api/Paper/GetQzglckwx", {
    kw: title.bt,
    txt: title.changwenben,
    mbid: moban.id,
  })
    .then((res) => {
      title.keys = res.data.kw;
      cankaowenxian.cankaowenxian = res.data.ckwx;
      cankaowenxian.cankaowenxianjingxuan = res.data.dxjx;
      qianzaiguilv.qianzaiguilvs = res.data.qzgl;
      title.fanhuichangwenben = res.data.subtxt;
    })
    .catch((err) => {
      loading.value = false;
      return Promise.reject({
        isNotErrorExpection: true,
      });
    });
};

// 默认取潜在规律的第一条
const huoquqianzaiguilvval = async () => {
  return new Promise((resolve, reject) => {
    let tempqianzaiguilv = qianzaiguilv.qianzaiguilvs.replace(/\n\n/g, "\n");
    qianzaiguilv.qianzaiguilv = tempqianzaiguilv.split("\n")[0];
    resolve();
  });
};

// 获取标题
const huoqubiaoti = async () => {
  title.titles.length=0
  return post("/api/Paper/GetTitleByQzgl", {
    kw: title.bt,
    txt: title.changwenben + title.fanhuichangwenben,
    txt2:'',
    qbqzgl: qianzaiguilv.qianzaiguilvs,
    qzgl: qianzaiguilv.qianzaiguilv,
    ckwx: cankaowenxian.cankaowenxian,
    dxjx: cankaowenxian.cankaowenxianjingxuan,
    mbid: moban.id,
  })
    .then((res) => {
      // 替换\n\n为\n
      let temptitle2 = res.data.replace(/\n\n/g, "\n");
      title.titles.push(...temptitle2.split("\n"));
      // 默认选中第一条
      title.title=title.titles[0]
    })
    .catch((err) => {
      loading.value = false;
      return Promise.reject({
        isNotErrorExpection: true,
      });
    });
};

// 生成论文
const shengchenglunwen=async()=>{
  if (cankaowenxian.cankaowenxian == "") {
    ElMessage({
      message: "请生成标题后再生成论文！",
      duration: 1000,
    });
    return;
  }
  if (title.title.trim() == "") {
    ElMessage({
      message: "标题不能为空",
      duration: 1000,
    });
    return;
  }
  return post("/api/Paper/SubReport", {
    mbid: moban.id,
    ybt:title.bt,
    title:title.title,
    subtxt:title.changwenben + title.fanhuichangwenben,
    subtxt2:'',
    kw: title.keys,
    ckwx: cankaowenxian.cankaowenxian,
    dxjx: cankaowenxian.cankaowenxianjingxuan,
    qzgl: qianzaiguilv.qianzaiguilv,
    qbqzgl: qianzaiguilv.qianzaiguilvs, 
  })
    .then((res) => {
      // 清空
      title.bt = "";
      title.title = "";
      title.titles.length = []
      title.changwenben = "";
      cankaowenxian.cankaowenxian = ""
      ElMessageBox.alert(
        "正在努力生成中，您可以继续生成其他论文或者关闭此页面，生成结果可在“个人中心-写作记录”中查看。",
        "提示",
        {
          confirmButtonText: "我知道了",
        }
      );
    })
    .catch((err) => {
      loading.value = false;
      return Promise.reject({
        isNotErrorExpection: true,
      });
    });
}




// 开始生成标题
const shengchengbiaoti = async () => {
  if (title.bt.trim() == "" || moban.id == 0) {
    ElMessage({
      message: "标题和模型不能为空",
      duration: 1000,
    });
    return;
  }
  loading.value = true;
  loadingtext.value = "正在生成中，当前进度0%，请耐心等待...";
  // 初始化
  randsum = 0;
  let conouttime = 60 + Math.floor(Math.random() * 5) + 1;
  loadingfun(conouttime);
  await huoquqianzaiguilv();
  await huoquqianzaiguilvval();
  await huoqubiaoti();
  loading.value = false;
};

// 查看结果
const clickckjg = () => {
  window.localStorage.setItem("navindex", "2-3-1");
  router.push({
    path: "/jsbg/lczyjl",
    query: {},
  });
};

// loading
const loadingfun = (outtime) => {
  let randNumtemp = Math.floor(Math.random() * 5) + 1;
  if (loading.value) {
    setTimeout(() => {
      randsum += randNumtemp;
      if (randsum < outtime) {
        let temprandnum = ((randsum / outtime) * 100).toFixed(2);
        loadingtext.value = `正在生成中，当前进度${temprandnum}%，请耐心等待...`;
        loadingfun(outtime);
      } else {
        loadingtext.value = `正在生成中，当前进度99.99%，请耐心等待...`;
      }
    }, randNumtemp * 1000);
  }
};
</script>

<style scoped>
:deep(.titleClass .el-radio-group) {
  align-items: flex-start;
  flex-direction: column;
}
:deep(.titleClass .el-radio) {
  white-space: normal;
  height: auto;
  min-height: 32px;
  line-height: 20px;
  margin: 0 0 0 0;
}
:deep(.el-input-group__prepend .el-button) {
  color: var(--el-button-text-color);
  background-color: var(--el-button-bg-color);
  border-color: var(--el-button-border-color);
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
:deep(.el-input-group__append .el-button) {
  color: var(--el-button-text-color);
  background-color: var(--el-button-bg-color);
  border-color: var(--el-button-border-color);
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

:deep(.el-checkbox) {
  white-space: normal;
}
:deep(.el-form-item__label) {
  font-weight: bold;
}
:deep(.tjlw-btns .el-button) {
  width: 200px;
  height: 50px;
  font-size: 16px;
}
.main {
  width: 100%;
  height: 100%;
  background: #fafafa;
  min-width: 1800px;
}

.ny-zhuti {
  position: relative;
  width: 100%;
  min-width: 1800px;
  min-height: 600px;
  height: calc(100% - 112px);
}

/* 流程 */
.ny-liucheng {
  position: absolute;
  left: 200px;
  top: 0;
  width: 400px;
  height: 100%;
  overflow-y: scroll;
  background: url("/img/common/bg-side.9a695b46.png") no-repeat;
  background-size: 100% 100%;
}
.ny-liucheng-title {
  margin-top: 82px;
  margin-bottom: 56px;
  font-size: 28px;
  color: #48a4ff;
  text-align: center;

  opacity: 1;
}
.ny-liucheng-li-title {
  width: 320px;
  height: 38px;
  background: linear-gradient(
    270deg,
    rgba(218, 249, 248, 0.6),
    rgba(179, 225, 251, 0.6)
  );
  border-radius: 8px 8px 8px 8px;
  opacity: 1;
  border: 1px solid hsla(0, 0%, 100%, 0.51);
  display: flex;
  align-items: center;
  position: relative;
  box-sizing: border-box;
  padding-left: 12px;
  font-weight: bold;
}
.ny-liucheng-li {
  width: 320px;
  height: auto;
  font-size: 14px;
  background: hsla(0, 0%, 100%, 0.3);
  border-radius: 8px 8px 8px 8px;
  opacity: 1;
  border: 1px solid hsla(0, 0%, 100%, 0.6);
  margin: 15px auto 20px;
}
.ny-liucheng-li-title-icon {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background: linear-gradient(147deg, #48b6ff, #4891ff);
  color: #fff;
  font-size: 12px;
  text-align: center;
  line-height: 18px;
  margin-right: 12px;
}

.ny-neirong {
  position: absolute;
  left: 600px;
  top: 0;
  width: calc(100% - 600px);
  min-width: 1200px;
  height: 100%;
  overflow-y: scroll;
}

.ny-nav {
  width: 1200px;
  height: 60px;
  margin: auto;
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #888;
}

.ny-nav a:link,
.ny-nav a:visited {
  color: #888;
  text-decoration: none;
}
.ny-nav a:hover {
  color: #2489f2;
}
.ny-con {
  width: 1200px;
  height: auto;
  background: #fff;
  box-shadow: 0 0 5px #ccc;
  border: 1px solid #dddfe5;
  border-radius: 2px;
  padding: 30px;
  margin: auto;
  box-sizing: border-box;
  margin-bottom: 50px;
}
.tjlw {
  width: 800px;
  margin: auto;
}
.tjlw-title {
  font-size: 30px;
  font-weight: 400;
  color: #3f4654;
  line-height: 42px;
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.tjlw-img {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.tjlw-img-txt {
  position: absolute;
  left: calc(50% - 400px);
  top: 36px;
  z-index: 1;
  width: 800px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
}
.biaotiyouhua-item {
  position: relative;
}
.biaotiyouhua {
  position: absolute;
  left: 0;
  top: 35px;
  width: 800px;
  height: auto;
  padding: 20px;
  box-sizing: border-box;
  font-size: 12px;
  background: #fff;
  box-shadow: 0 6px 20px 1px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  border: 1px solid #ececee;
  z-index: 10;
}
.biaotiyouhua-title {
  display: flex;
  align-items: center;
}
.biaotiyouhua-bt {
  padding: 2px 0;
}
.biaotiyouhua-bts {
  height: 32px;
  display: flex;
  align-items: center;
  padding-left: 20px;
  border-radius: 4px;
  cursor: pointer;
}
.biaotiyouhua-bts:hover {
  background: #f1f5ff;
  color: #2489f2;
}
.biaotiyouhua-hyp {
  width: 70px;
  cursor: pointer;
  padding: 0 0 0 20px;
  display: flex;
  align-items: center;
  color: #2489f2;
}
.tjlw-btns {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 30px 0 20px 0;
}
.cankaowx {
  width: 800px;
  height: 328px;
  overflow: hidden;
  padding: 6px 20px;
  box-sizing: border-box;
  font-size: 12px;
  background: #f7f7f7;
  border-radius: 4px;
}
.cankaowx2 {
  width: 800px;
  height: auto;
  padding: 6px 20px;
  box-sizing: border-box;
  font-size: 12px;
  background: #f7f7f7;
  border-radius: 4px;
}
.cankaowx-more {
  width: 100%;
  font-size: 14px;
  text-align: center;
}
.cankaowx-more > span {
  cursor: pointer;
  color: #e6a23c;
}
.cankaowx-more > span:hover {
  text-decoration: underline;
}

.loadings {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 100;
  width: 100%;
  height: 100%;
}
.tjlw-btns-link {
  margin-left: 40px;
  font-size: 14px;
  color: #2489f2;
  cursor: pointer;
}
.tjlw-btns-link:hover {
  text-decoration: underline;
}
.ckwx-btn {
  padding: 0 0 10px 0;
}

/* 美化滚动条 */
::-webkit-scrollbar {
  width: 6px;
  height: 10px;
}

::-webkit-scrollbar-track {
  width: 6px;
  background: rgba(#101f1c, 0.1);
  -webkit-border-radius: 2em;
  -moz-border-radius: 2em;
  border-radius: 2em;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(144, 147, 153, 0.1);
  background-clip: padding-box;
  min-height: 28px;
  -webkit-border-radius: 2em;
  -moz-border-radius: 2em;
  border-radius: 2em;
  transition: background-color 0.3s;
  cursor: pointer;
}

::-webkit-scrollbar-thumb:hover {
  background-color: rgba(144, 147, 153, 0.3);
}
</style>
